import MobileDetect from '../../default/js/shared/helpers/mobile-detect';

const mobileDetect = new MobileDetect();

window.addEventListener('load', () => {
  // Footer accordion
  const footerLinks = document.querySelectorAll('.footer__links');
  if(!footerLinks) {
    return;
  }

  footerLinks.forEach(el => {
    if(el.firstChild) {
      el.firstChild.addEventListener('click', function () {
        el.classList.toggle('opened');
      });
    }
  });
});

window.addEventListener('load', () => {
  if (mobileDetect.mobile()) {
    const menuItems = document.querySelectorAll('.--menu .menu > ul > li');

    menuItems.forEach((item) => {
      item.firstElementChild.addEventListener('click', (e) => {
        if (item.classList.contains('--has-children')) {
          e.preventDefault();
          item.classList.toggle('--active');
        }
      });
    });

    const topMenu = document.getElementsByClassName('top__menu');
    if (topMenu.length > 0) {
      topMenu[0].firstElementChild.addEventListener('click', (e) => {
        topMenu[0].querySelector('.language__dropdown .language__dropdown').classList.toggle('--active');
      });
    }
  }
});

('nav.main__navigation>ul').dropDown(el => {
    el.classList.add('--active');
}, el => {
    el.classList.remove('--active');
}, 200);
